import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CanDeactivateComponent } from 'src/app/core/abstractions/can-deactivate.component';
import { UnsavedChangesModalComponent } from 'src/app/core/components/unsaved-changes-modal/unsaved-changes-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard

{
    constructor(
        private translate: TranslateService,
        private dialog: MatDialog
    ) {}

    canDeactivate(
        component: CanDeactivateComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ) {
        if (nextState.url.indexOf('authentication') > -1) {
            return true;
        }
        if (!component.canDeactivate()) {
            const subject = new Subject<boolean>();
            this.dialog.closeAll();
            const modal = this.dialog.open(UnsavedChangesModalComponent, {
                panelClass: 'curved-modal'
            });
            modal.componentInstance.subject = subject;
            return subject.asObservable();
        }
        return true;
    }
}
